body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-layout);
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter-SemiBold.ttf);
}

* {
  font-size: 12px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

:root {
  --primary-color: #5b73e8;
}

.d-inline-block {
  display: inline-block !important;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.relative {
  position: relative;
}

.fw-500 {
  font-weight: 500 !important;
}

.text-center {
  text-align: center !important;
}

.h-100 {
  height: 100% !important;
}
.fs-20 {
  font-size: 20px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.center,
.ant-segmented-item-label,
.ant-dropdown-menu-title-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.nowrap {
  white-space: nowrap;
}
.relative {
  position: relative;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.space-between {
  display: flex;
  justify-content: space-between !important;
}
.pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.ant-input-prefix {
  margin-right: 0px !important;
}

.ant-input-affix-wrapper {
  padding: 0px 11px !important;
}

.ant-input {
  padding: 8px 11px !important;
}

.text-ellipsis {
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}
.relative {
  position: relative;
}
.text-ellipsis-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button-primary {
  width: 100%;
  height: 45px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ant-picker,
.ant-select-single {
  height: 40px;
}

.ml-10 {
  margin-left: 10px !important;
}

.loading-indicator {
  position: fixed;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
}

.ant-btn-link {
  color: var(--primary-color);
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.login-step .ant-popover-arrow {
  top: 5px;
  right: 45px;
  width: 32px;
  height: 16px;
}

.login-step .ant-popover-arrow::before {
  background: transparent;
  clip-path: unset;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid var(--background-arrow-popover);
}

.login-step .ant-popover-content {
  margin-top: 4px;
}

.login-step .ant-popover-inner {
  padding: 0px;
  border-radius: 10px;
  background-color: transparent;
  background-size: cover;
  box-shadow: unset !important;
}

.color-primary {
  color: var(--color-primary) !important;
}

.color-money {
  color: var(--table-footer-display) !important;
}

.search-bill .ant-popover-inner {
  background-color: var(--search-bill-background);
  box-shadow: var(--search-bill-box-shadow);
  border-radius: 20px;
  padding: 0px;
}

.d-flex {
  display: flex;
}

/* .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background: var(--login-confirm-background);
} */
